import {
  LifebuoyIcon,
  NewspaperIcon,
  PhoneIcon,
} from "@heroicons/react/20/solid";
import React from "react";
import map from '../../images/contactbg.jpg'


const cards = [
  {
    name: "Sales",
    description:
      "(+91) - 8851392636",
    icon: PhoneIcon,
  },
  {
    name: "Technical Support",
    description:
      "Helpcentre@diagnoos.com",
    icon: LifebuoyIcon,
  },
  {
    name: "Media Inquiries",
    description:
      "Support@diagnoos.com",
    icon: NewspaperIcon,
  },
];

export default function Contact() {
  return (
    <>
      
        <section className=" py-2 px-0 " >
        <div className="relative isolate overflow-hidden py-24 sm:py-32 mt-24 bg-black">
        <img
        className="absolute w-full  -z-50"
        src={map}
        alt=""
        width={1200}
        height={1200}
      />
          <div className="mx-auto max-w-7xl px-2 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h2 className="text-4xl font-extrabold tracking-tight  text-white sm:text-6xl" data-aos="fade-up">
                Help center
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-100" data-aos="fade-up">
                Diagnoos: Your steadfast partner, always there to help. Your health, our priority
              </p>
            </div>
            <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-8 ">
              {cards.map((card) => (
              
                <div
                  key={card.name}
                  className="flex gap-x-4 rounded-xl bg-white/5 p-6 ring-2 ring-inset ring-white/80"
                  data-aos="fade-up"
                >
                  <card.icon
                    className="h-7 w-5 lg:h-16 lg:w-16 flex-none text-gray-300"
                    aria-hidden="true"
                  />
                  <div className="text-base">
                    <h3 className="font-semibold lg:text-xl text-white leading-7" data-aos="fade-up">{card.name}</h3>
                    <p className="mt-2` text-sm lg:text-xl text-white leading-5" data-aos="fade-up">{card.description}</p>
                  </div>
                </div>

              ))}

            </div>
          </div>
        </div>
        </section>
      
    </>
  );
}
