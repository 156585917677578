import React from "react";
import MainContainer from "./MainContainer";

const Jobcard = () => {
  const content = [
    {
      serviceName: "Pharamacy Connect:",
      // serviceContent:
      //   " Our expert consultants conduct in-depth assessments of your existing IT...",
      serviceImg:
        "https://www.menap-smi.org/wp-content/uploads/title-banner-The-Role-of-the-Pharmacist-in-Self-Care-and-Self-Medication.jpg",
      serviceLink: "",
    },
    {
      serviceName: "Labs Connect:",
      // serviceContent:
      //   " With our cutting-edge software development expertise, we create tailored ...",
      serviceImg:
        "https://wp02-media.cdn.ihealthspot.com/wp-content/uploads/sites/522/2022/06/iStock-1174677845.jpg",
      serviceLink: "",
    },
    {
      serviceName: "Doctors Connect:",
      // serviceContent:
      //   "Experience the power of cloud technology through our comprehensive range of ...",
      serviceImg:
        "https://cdn.ndtv.com/tech/images/gadgets/doctor_handshake_ndtv.jpg",
      serviceLink: "",
    },
    {
      serviceName: "Clinics Connect:",
      // serviceContent:
      //   "Safeguarding your digital assets is our top priority. Our cybersecurity solutions include...",
      serviceImg:
        "https://aiht.edu/wp-content/uploads/2020/03/Clinics-Advantages-and-Disadvantages.png",
      serviceLink: "",
    },

    {
      serviceName: "Physiotherapist Connect:",
      // serviceContent:
      //   "Safeguarding your digital assets is our top priority. Our cybersecurity solutions include...",
      serviceImg:
        "https://ultimatehealthclinic.ca/wp-content/uploads/2021/03/reasons-should-see-physiotherapist.jpg",
      serviceLink: "",
    },
  ];

  return (
    <>
      {/* <div className=" py-8 pt-16 lg:pt-16 bg-blue-50"></div> */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className=" mx-auto max-w-2xl lg:mx-0">
          <h2
            className="text-4xl  tracking-tight text-black sm:text-4xl lg:text-6xl font-extrabold"
            data-aos="fade-up"
          >
            MediConnect
          </h2>
          <p
            className="mt-6 text-lg leading-8 text-gray-600"
            data-aos="fade-up"
          >
            We’re a group of individuals who are passionate about what we do and
            dedicated to delivering the best results for our clients.
          </p>
        </div>
      </div>

      <div className=" py-16 lg:py-16 px-8 lg:px-24 w-[100%] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">

        {content.map((service, index) => {
          return (
            <MainContainer
              key={index}
              serviceName={service.serviceName}
              // serviceContent={service.serviceContent}
              Img={service.serviceImg}
              serviceLink={service.serviceLink}
            />
          );

        })}
      </div>
      <div />
    </>
  );
};

export default Jobcard;
