
// import { Link } from 'react-router-dom'
// import { StarRating } from '../../components/StarRating'
import coverImage from '../../images/cover1.png'

// function Testimonial() {
//   return (
//     <figure className="relative mx-auto max-w-md text-center lg:mx-0 lg:text-left" data-aos="fade-up">
//       <div className="flex justify-center text-yellow-400 lg:justify-start" data-aos="fade-up">
//         <StarRating />
//       </div>
//       <blockquote className="mt-2">
//         <p className="font-display text-xl font-medium text-slate-900" data-aos="fade-up">
//         “Diagnosing the problem is the first step towards finding the cure.”
//         </p>
//       </blockquote>
//       <figcaption className="mt-2 text-sm text-slate-500">
//         <strong className="font-semibold text-blue-600 before:content-['—_']">
//         Diagnoos
//         </strong>
       
//       </figcaption>
//     </figure>
//   )
// }

export function About3() {
  return (
    <header className="bg-blue-50  overflow-hidden   lg:px-5">
      <div className=" mx-auto grid max-w-6xl grid-cols-1 grid-rows-[auto_1fr] gap-y-16 pt-16 md:pt-20 lg:grid-cols-12 lg:gap-y-20 lg:px-3 lg:pb-36 lg:pt-20 xl:py-32">
        <div className="  relative flex items-end lg:col-span-5 lg:row-span-2">
          <div className="absolute -bottom-12 -top-20 left-0 right-1/2 z-10 rounded-br-6xl bg-[url('../public/images/aboutimg1.png')] justify-center  bg-no-repeat bg-cover bg-center text-white/10 md:bottom-8 lg:-inset-y-32 lg:left-[-100vw] lg:right-full lg:-mr-40">
       
          </div>
          <div className="relative z-10 mx-auto flex w-64 rounded-xl bg-slate-600 shadow-xl md:w-80 lg:w-auto" >
            <img className="w-full rounded-xl shadow-[0_35px_60px_-15px_rgba(0,0,0,0.6)] " src={coverImage} alt="" />
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:col-span-7 lg:pb-14 lg:pl-16 lg:pr-0 xl:pl-20">
          <div className="hidden lg:absolute lg:-top-32 lg:bottom-0 lg:left-[-100vw] lg:right-[-100vw] lg:block lg:bg-slate-200" />
          {/* <Testimonial /> */}
        </div>
        <div className="bg-blue-50 pt-16 lg:col-span-7 lg:bg-transparent lg:pl-16 lg:pt-0 xl:pl-20">
          <div className=" mx-auto px-4 sm:px-6 md:max-w-2xl md:px-4 lg:px-0">
            <h1 className="font-display text-5xl font-extrabold text-slate-900 sm:text-6xl">
              Discover the Vast Realm of Medical Knowledge:{" "}
            </h1>
            <p className="mt-4 text-3xl text-slate-600" >
               Explore the World of Health, Treatments, and Research
            </p>
            <div className="mt-8 flex gap-4">
              <a href="#diagnoos" color="blue" 
              className='bg-blue-500 p-2 rounded-md text-white' 
              >
                About Diagnoos
              </a>
              <a href="#team" variant="outline" color="blue"
              className='p-2 border-2 border-sky-500 rounded-md text-blue-500' 
              >
              Our Team
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
