import React from "react";
import Heropg from "./../components/Landing/Heropgnew";
import { About2 } from '../components/About/About2'
// import {Smallform}  from '../components/Extraaddon/Smallform'
// import About from "./../components/About/About";
import Medicard from "../components/Extraaddon/Medicard";
import Labs from "../components/labs/Labs";
import Faqnew from "../components/Landing/Faqnew";
// import { motion, AnimatePresence } from "framer-motion";


const Home = () => {

  return (
    <>
      <Heropg />
      <About2/>
      <Labs/>
      <Medicard/>
      <Faqnew/>
      {/* <Smallform /> */}
      
    </>
  );
};

export default Home;
