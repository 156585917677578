import { Container } from "../../components/Container";
import { motion } from "framer-motion";

export function Labform() {

  const textAnimation = {
    initial: { opacity: 0, x: -120 },
    animate: { opacity: 1, x: 0 },
    transition: { duration: 1.4 },
  };

  return (
    <section
      id="free-chapters"
      aria-label="Free preview"
      className="scroll-mt-6  sm:scroll-mt-12 p-4"
    >
      <motion.div className="overflow-hidden lg:relative" {...textAnimation}>
        <Container
          size="md"
          className="relative grid grid-cols-1 lg:grid-cols-1 gap-y-6 lg:static items-center py-14"
        >
          <div>
            <h2
              className="font-display text-3xl lg:text-6xl font-extrabold tracking-tight text-gray-200 sm:w-3/4 sm:text-6xl md:w-2/3 lg:w-auto"
              // data-aos="fade-up"
            >
              Book An Appointment
            </h2>
            <p
              className="mt-4 text-lg tracking-tight text-gray-300"
              // data-aos="fade-up"
            >
              Stay informed with the latest medical updates by connecting with
              us and accessing the most up-to-date information.
            </p>
          </div>
          <form className="" >
            
            <div className="mt-4 sm:relative sm:flex sm:items-center sm:py-0.5 sm:pr-2.5">
              
              <button
                type="submit"
                color="white"
                className="mt-4 w-full bg-[#84C859] hover:bg-white hover:text-black text-white py-2 px-8 rounded-md sm:relative sm:z-10 sm:mt-0 sm:w-auto sm:flex-none"
               
              >
                <a href="/">

                Book Here
                </a>
              </button>
            </div>
          </form>
        </Container>
      </motion.div>
    </section>
  );
}
