import React from "react";
import MainContainer from "./MainContainer";

const Techjob = () => {
  const content = [
    {
      serviceName: "Front-end Developer:",
      serviceContent:
        " Our expert consultants conduct in-depth assessments of your existing IT...",
      serviceImg:
        "https://www.simplilearn.com/ice9/free_resources_article_thumb/Become_a_Front_End_Developer.jpg",
      serviceLink: "",
    },
    {
      serviceName: "Back-end Developer:",
      serviceContent:
        " With our cutting-edge software development expertise, we create tailored ...",
      serviceImg:
        "https://verpex.com/assets/uploads/images/blog/How-to-become-a-Backend-Developer.jpg?v=1665484477",
      serviceLink: "",
    },
    {
      serviceName: "Cloud Engineer:",
      serviceContent:
        "Experience the power of cloud technology through our comprehensive range of ...",
      serviceImg:
        "https://i0.wp.com/biztechcollege.com/wp-content/uploads/2021/05/Cloud-Engineer.png?fit=1920%2C1080&ssl=1",
      serviceLink: "",
    },
    {
      serviceName: "Graphic Designer:",
      serviceContent:
        "Safeguarding your digital assets is our top priority. Our cybersecurity solutions include...",
      serviceImg:
        "https://cdn.logojoy.com/wp-content/uploads/20200612115158/AdobeStock_88222430-1-min-scaled.jpeg",
      serviceLink: "",
    },

    {
      serviceName: "AI/ML Developer:",
      serviceContent:
        "Safeguarding your digital assets is our top priority. Our cybersecurity solutions include...",
      serviceImg:
        "https://www.distilnfo.com/itadvisory/files/2018/07/Artificial-Intelligence-machine-learning.png",
      serviceLink: "",
    },
  ];

  
  return (
    <>
      <div className=" py-12 pt-16 lg:pt-16 bg-[url('../public/images/cc2.jpg')] bg-cover bg-fixed bg-top bg-no-repeat"> 
      <h1 className="p-24 opacity-0">h</h1>
      </div>

      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className=" mx-auto max-w-2xl lg:mx-0">
          <h2
            className="text-4xl pt-6 tracking-tight text-black sm:text-4xl lg:text-6xl font-extrabold"
            data-aos="fade-up"
          >
            TechConnect
          </h2>
          <p
            className="mt-6 text-lg leading-8 text-gray-600"
            data-aos="fade-up"
          >
            Exciting tech openings! Seeking skilled professionals to innovate and elevate our projects. Collaborate on cutting-edge solutions, solve complex challenges, and drive technological advancement. Join our dynamic team and shape the future of technology.
          </p>
        </div>
      </div>

      <div className=" py-16 lg:py-16 px-8 lg:px-24 w-[100%] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
        {content.map((service, index) => {
          return (
            <MainContainer
              key={index}
              serviceName={service.serviceName}
              serviceContent={service.serviceContent}
              Img={service.serviceImg}
              serviceLink={service.serviceLink}
            />
          );
        })}
      </div>
      <div />
    </>
  );
};

export default Techjob;
