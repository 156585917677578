import React from "react";

const About = () => {
  return (
    <div id="diagnoos">
      <div className=" bg-gradient-to-t from-white from-10% via-white via-10% to-blue-50 to-90% pb-10">
        <div className="pt-22 overflow-hidden sm:mt-0 sm:pt-20">
          <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
              <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
                <h2 className="text-3xl lg:text-6xl tracking-tight text-gray-900 sm:text-4xl  font-extrabold">
                  What is <span className="text-5xl lg:text-8xl bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-green-600">Diagnoos?</span> 
                </h2>
                <p className="mt-6 text-xl leading-8 text-gray-600">
                  Our platform is designed to help healthcare providers optimize
                  their operations, improve patient outcomes, and reduce costs.
                  We believe that the key to successful healthcare delivery is
                  collaboration and partnership between healthcare providers,
                  patients, and technology companies like ours.
                </p>
                <p className="mt-6 text-base leading-7 text-gray-600">
                  we're not just building technology—we're building
                  relationships.
                </p>
              </div>
              <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
                <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
                  <img
                    src="https://assets.telegraphindia.com/telegraph/2022/Jan/1642452524_1634767350_doctor.jpg"
                    alt="b"
                    className="rounded-2xl"
                  />
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
