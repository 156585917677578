// import Lab1 from "../../Assets/lab1.png";
import Lab from "../../Assets/homeimg.png";

import React from "react";
import { motion } from "framer-motion";

import { Labform } from "./Labform";

const Labs = () => {

  const textAnimation = {
    initial: { opacity: 0, x: 120 },
    animate: { opacity: 1, x: 0 },
    transition: { duration: 1.9 },
  };



  return (
    <div className="py-6 lg:py-16 lg:px-14 bg-[#0F4D52]">
      <div className=" w-full ">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-5">
          <motion.div className=" w-full" {...textAnimation}>
            <img
              src={Lab}
              className="lg:h-[80vh] w-full "
              alt="Diagnoos"
            />
           
          </motion.div>

          <div className=" lg:px-6 lg:py-4 w-full">
            <Labform />
          </div>
        </div>
       
      </div>
    </div>
  );
};

export default Labs;
