import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { BsList, BsX } from "react-icons/bs";
import { useMediaQuery } from "react-responsive";
import Logo from "../../Assets/Dlogo3.png";
// import Logo from "../../Assets/logollp.png";

import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import Navbarnew from "./Navbarnew";
import { Link } from "react-router-dom";
// import SignIn from "../account/Signin";

export default function Navbar() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [backgroundwhite, setBackgroundWhite] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: "768px" });

  const handleWindowScroll = (e) => {
    const height = window.scrollY;
    const tresholdHeight = 50;

    if (height > tresholdHeight) {
      setBackgroundWhite(true);
    } else {
      setBackgroundWhite(false);
    }
  };

  const handleBlackScreenClick = (e) => {
    e.stopPropagation();
    setDropdownOpen(false);
  };

  useEffect(() => {
    if (isMobile) {
      setDropdownOpen(false);
    }
  }, [isMobile]);

  useEffect(() => {
    setBackgroundWhite(dropdownOpen);
  }, [dropdownOpen]);

  useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);

    return () => window.removeEventListener("scroll", handleWindowScroll);
  }, []);

  return (
    //#e0e1dd - grey
    //#cce3de - green
    //#eaf4f4
    // #9f86c0

    <nav
      className={classNames(
        "fixed w-full transition-all duration-800 py-4 z-50  ",
        {
          "  bg-[#cce3de] bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-50 z-50":
            backgroundwhite,
        }
      )}
    >
      <div className="px-4 container mx-auto top-0 flex justify-between items-center ">
        <div className="flex items-center">
          <Link to="/">
            <img
              src={Logo}
              className="mr-6 h-10 w-50 cursor-pointer"
              alt="Diagnoos"
            />
          </Link>
          <div className="hidden mx-4 gap-8 lg:flex">
            <Navbarnew />
          </div>
        </div>
        <div className="hidden gap-4 lg:flex">

            <a href="/Signin">
          <SecondaryButton className="">
              Sign In
          </SecondaryButton>
            </a>

          <a href="/Signup">
          <PrimaryButton className="bg-blue-500">
                    Sign Up
            </PrimaryButton>
                    </a>
        </div>
        <div className="lg:hidden text-2xl">
          <button
            className="z-50 p-4 block transition-all"
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            {dropdownOpen ? <BsX /> : <BsList />}
          </button>

          {/* Menu dropdown */}
          <div
            className={classNames({
              "text-base left-0 top-full right-0 absolute transition-all duration-400": true,
              "invisible opacity-0": !dropdownOpen,
              "visible opacity-100": dropdownOpen,
            })}
          >
            <div
              className="h-screen left-0 bg-black bg-opacity-30"
              onClick={handleBlackScreenClick}
            >
              <div className="z-20 shadow-xl bg-white p-6">
                <div className="gap-4 flex mb-6">

                    <a href="/Signin">
                  <SecondaryButton className="w-full">
                      Sign In
                  </SecondaryButton>
                      </a>

                  <a href="/Signup">
                  <PrimaryButton className="w-full bg-blue-500">
                    Sign Up
                  </PrimaryButton>
                    </a>
                </div>
                <div className="mb-4">
                  <Navbarnew />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
