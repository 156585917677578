import Contact from "./../components/Contact/Contact";
// import Follow from "../components/Landing/Follow";
import Contacttext from "../components/Contact/Contacttext";


export default function contact() {
  return (
    <>
      <Contact />
      <Contacttext/>
      {/* <Follow/> */}
    </>
  );
}
