import React, { useEffect, useState } from "react";

import { About3 } from '../components/About/About3'
// import Team from "../components/Team/Team";
// import About from "./../components/About/About";
// import Ourvalues from "../components/Extraaddon/Ourvalues";
import About from '../components/About/About';
import Newcomp from '../components/Extraaddon/Newcomp';
import Newcompmob from '../components/Extraaddon/Newcompmob';


const Plans = () => {

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 765); // Adjust the breakpoint as needed
    };

    // Initial check on mount
    handleResize();

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
    <About3/>
    <About/>
    {/* <Ourvalues /> */}

    {/* <Newcomp/> */}
    {isMobile ? <Newcompmob /> : <Newcomp />}
    {/* <Team/> */}
   
    </>
  )
}

export default Plans