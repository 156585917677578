import React from "react";

import lab from "../../Assets/labpg1.jpg";
import lab2 from "../../Assets/labimg2.png";
import { motion} from 'framer-motion';


const Labinfo = () => {

  return (
    <>
      <div>
        <motion.div className="lg:h-[100vh]  lg:pt-24 pt-24 pb-16 lg:pb-24 w-full px-4 lg:px-24 ">
          <img
            src={lab}
            className="lg:h-[70vh] h-[25vh] w-full rounded-md shadow-2xl"
            alt="Diagnoos"
          />
        </motion.div>

        <div className=" bg-blue-50 shadow-xl rounded-t-3xl py-4 lg:py-6 w-[90%] lg:w-[80%] mx-auto">
          <motion.h1 className="text-3xl lg:text-6xl font-bold mx-auto text-center justify-center py-6">
            {" "}
            Steps To Complete{" "}
          </motion.h1>

        

          <div className=" mx-auto text-center w-full justify-center py-4 lg:py-8">
            <ol className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 px-2 gap-5">
              <motion.li className="mx-auto flex items-center text-blue-600 dark:text-blue-500 space-x-2.5 rtl:space-x-reverse">
                <span className="flex items-center justify-center w-8 h-8 border border-blue-600 rounded-full shrink-0 dark:border-blue-500">
                  1
                </span>
                <span>
                  <h3 className="font-medium leading-tight">User info</h3>
                  <p className="text-sm">Step details here</p>
                </span>
              </motion.li>
              <motion.li className="mx-auto flex items-center text-orange-600 dark:text-orange-500 space-x-2.5 rtl:space-x-reverse">
                <span className="flex items-center justify-center w-8 h-8 border border-orange-500 rounded-full shrink-0 dark:border-orange-400">
                  2
                </span>
                <span>
                  <h3 className="font-medium leading-tight">Company info</h3>
                  <p className="text-sm">Step details here</p>
                </span>
              </motion.li>
              <motion.li className="mx-auto flex items-center text-green-600 dark:text-green-500 space-x-2.5 rtl:space-x-reverse">
                <span className="flex items-center justify-center w-8 h-8 border border-green-500 rounded-full shrink-0 dark:border-green-400">
                  3
                </span>
                <span>
                  <h3 className="font-medium leading-tight">Payment info</h3>
                  <p className="text-sm">Step details here</p>
                </span>
              </motion.li>
            </ol>
          </div>

        </div>


        <div className="bg-blue-500 shadow-xl text-white my-4 py-8 lg:my-8 rounded-b-3xl  w-[90%] lg:w-[80%] mx-auto">
            
            <motion.div className="px-4 lg:px-16 py-4">
            <h1 className="text-4xl font-bold">
                Introduction
            </h1>
            <p className="py-4 lg:text-xl lg:w-[80%]">Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis blanditiis aut explicabo eveniet ex culpa suscipit rem. Minus ratione quibusdam voluptatum accusantium debitis saepe quasi autem, pariatur maiores dolorum aut?</p>
            </motion.div>

            <motion.div className="px-4 lg:px-16 py-4">
            <h1 className="text-4xl font-bold">
                Introduction
            </h1>
            <p className="py-4 lg:text-xl lg:w-[80%]">Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis blanditiis aut explicabo eveniet ex culpa suscipit rem. Minus ratione quibusdam voluptatum accusantium debitis saepe quasi autem, pariatur maiores dolorum aut?</p>
            </motion.div>

            <motion.div className="px-4 lg:px-16 py-4">
            <h1 className="text-4xl font-bold">
                Introduction
            </h1>
            <p className="py-4 lg:text-xl lg:w-[80%]">Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis blanditiis aut explicabo eveniet ex culpa suscipit rem. Minus ratione quibusdam voluptatum accusantium debitis saepe quasi autem, pariatur maiores dolorum aut?</p>
            </motion.div>

            <motion.div className="px-4 lg:px-16 py-4">
            <h1 className="text-4xl font-bold">
                Introduction
            </h1>
            <p className="py-4 lg:text-xl lg:w-[80%]">Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis blanditiis aut explicabo eveniet ex culpa suscipit rem. Minus ratione quibusdam voluptatum accusantium debitis saepe quasi autem, pariatur maiores dolorum aut?</p>
            </motion.div>

        </div>

        <motion.div className=" lg:h-[90vh] py-8 lg:py-24 w-full px-4 lg:px-24">
          <img
            src={lab2}
            className="lg:h-[60vh] w-[100%] lg:w-[90%] h-[25vh] mx-auto rounded-md shadow-2xl"
            alt="Diagnoos"
          />
        </motion.div>
      </div>
    </>
  );
};

export default Labinfo;
