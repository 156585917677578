import React from 'react';


export default function Navbarnew() {
  return (
    <>
  
      <div className="w-full  ">
        <a href="/" className='lg:text-[20px] border-b-[1px] border-transparent hover:border-black block'>Home</a>
      </div>
      <div className="w-full ">
        <a href="/about" className=' lg:text-[20px] border-b-[1px] border-transparent hover:border-black block'>About</a>
      </div>
      <div className="w-full ">
        <a href="/contact" className=' lg:text-[20px] border-b-[1px] border-transparent hover:border-black block'>Contact</a>
      </div>
      {/* <div className="w-full ">
        <a href="/tests" className='lg:text-[20px] border-b-[1px] border-transparent hover:border-black block'>Support</a>
      </div> */}
      <div className="w-full ">
        <a href="/career" className='lg:text-[20px] border-b-[1px] border-transparent hover:border-black block'>Career</a>
      </div>
      {/* <div className="w-full ">
        <a href="/tests" className='border-b-[1px] border-transparent hover:border-black block'>Support</a>
      </div> */}
     
    </>
  );
}