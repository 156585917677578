import React from "react";
import Sup from "../../Assets/support1.png";
import lab from "../../Assets/lab3.png";

// import Contactform from "./Contactform";
// import { Container } from "../../components/Container";

const Support = () => {
  return (
    <div>
      {/* <div className=" py-8 pt-16 lg:pt-16 lg:bg-[#0F4D52]"></div> */}
      <div className=" w-full lg:pb-24 bg-[#0F4D52]">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-5">
          <div className="lg:h-[90vh] w-full bg-green-100">
            <img
              src={Sup}
              className="lg:h-[90vh] h-[50vh] w-full "
              alt="Diagnoos"
            />
          </div>

          <div className="lg:h-[90vh] lg:px-12 lg:py-4 w-full ">
            {/* <Contactform /> */}
            <section class=" p-4  bg-no-repeat bg-cover  bg-opacity-20">
              <div class="py-4 lg:py-4 px-4 mx-auto max-w-screen-md">
                <h2 class="mb-4 text-2xl lg:text-6xl tracking-tight font-extrabold text-center text-gray-900 dark:text-gray-200">
                  Join Our Support Team
                </h2>

                <p className="my-8 text-lg tracking-tight text-gray-300">
                  Are you ready to take the next step in your career? <br/> Join our
                  dynamic team at <span className="font-bold text-green-400">Diagnoos</span> and be part of a culture
                  that values innovation, collaboration, and personal growth. We
                  offer exciting opportunities for professional development, a
                  supportive and inclusive environment, and the chance to work
                  on impactful projects that make a real difference. At <span className="font-bold text-green-400">Diagnoos</span>, we prioritize your well-being with competitive
                  benefits and flexible work arrangements. If you're passionate,
                  driven, and ready to contribute to a thriving team, we want to
                  hear from you! Apply now and embark on a rewarding journey
                  with us.
                </p>

                <button
                  type="submit"
                  class="my-6 px-12 w-[100%] bg-[#84C859] hover:bg-white text-white hover:text-[#0F4D52] p-4 rounded-md"
                >
                  Connect Here
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>

      {/* -------------------lab connect info--------------------------------------------------- */}

      <div className=" w-full lg:mb-24 bg-[#0F4D52]">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-5">
          <div className=" lg:px-12 lg:py-4 w-full ">
            <div
              size="md"
              className="relative grid grid-cols-1 lg:grid-cols-1 gap-y-6 lg:static items-center py-14 px-6 lg:px-12"
            >
              <div>
                <h2 className="font-display text-3xl lg:text-6xl font-extrabold tracking-tight text-gray-200 sm:w-3/4 sm:text-6xl md:w-2/3 lg:w-auto">
                  Apply For Lab Connect
                </h2>
                <p className="mt-4 text-lg tracking-tight text-gray-300">
                  Stay informed with the latest medical updates by connecting
                  with us and accessing the most up-to-date information.
                </p>
              </div>

              <div className="mt-4 sm:relative sm:flex sm:items-center sm:py-0.5 sm:pr-2.5">
                <button
                  type="submit"
                  class="my-6 px-12 w-[100%] bg-[#84C859] hover:bg-white text-white hover:text-[#0F4D52] p-4 rounded-md"
                >
                  Connect Here
                </button>
              </div>
            </div>
          </div>

          <div className=" py-6 w-full ">
            <img
              src={lab}
              className="lg:h-[80vh] h-[50vh] w-full "
              alt="Diagnoos"
            />
          </div>
        </div>

        {/* <div className=" py-8 pt-16 lg:pt-16 lg:bg-[#0F4D52]"></div> */}
      </div>
    </div>
  );
};

export default Support;
