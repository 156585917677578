import React from 'react'
// import Medicard from "../components/Extraaddon/Medicard";
import Jobcard from '../components/Career/Jobcard';
import Techjob from '../components/Career/Techjob';
import Support from '../components/Career/Support';

const Careerpg = () => {
  return (
    <>
    {/* <Medicard/> */}
    <div className=" py-8 pt-16 lg:pt-16 bg-blue-50"></div>
    <Support/>
    <Jobcard/>
    <Techjob/>
    </>
  )
}

export default Careerpg