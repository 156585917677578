
import { useEffect, useState } from 'react'
// import Image from 'next/image'
import { Tab } from '@headlessui/react'
import clsx from 'clsx'

import backgroundImage from '../../images/bg7.svg'
// import screenshotExpenses from '../../images/bg1.jpeg'
import Ld1 from '../../images/ld1.png'

import screenshotPayroll from '../../images/bg2.jpeg'
import screenshotReporting from '../../images/bg3.jpeg'
import screenshotVatReturns from '../../images/LABREPOTS.jpg'
import { motion } from "framer-motion";

const features = [
  {
    title: 'Medical Reports',
    description:
      "Patient medical report : blood pressure, heart rate, and respiratory rate and medical history",
    image: screenshotPayroll,
  },
  {
    title: 'Appointments',
    description:
      "The patient discussed any current health concerns, and the doctor provided appropriate medical advice and recommendations. A follow-up visit was scheduled for a future date as required.",
    image: Ld1,
  },
  {
    title: 'Labs',
    description:
      "They analyze blood, urine, tissue samples, and more to detect diseases, monitor health, and assess treatment effectiveness.",
    image: screenshotVatReturns,
  },
  {
    title: 'Telemedicine ',
    description:
      'Telemedicine patients can to consult with Doctor from a distance, using video conferencing or phone calls.',
    image: screenshotReporting,
  },
]

export function About2() {

  const textAnimation = {
    initial: { opacity: 0, x: 120 },
    animate: { opacity: 1, x: 0 },
    transition: { duration: 1.9 },
  };

  const textAnimation2 = {
    initial: { opacity: 0, x: -120 },
    animate: { opacity: 1, x: 0 },
    transition: { duration: 1.9 },
  };


  let [tabOrientation, setTabOrientation] = useState('horizontal')

  useEffect(() => {
    let lgMediaQuery = window.matchMedia('(min-width: 1024px)')

    function onMediaQueryChange({ matches }) {
      setTabOrientation(matches ? 'vertical' : 'horizontal')
    }

    onMediaQueryChange(lgMediaQuery)
    lgMediaQuery.addEventListener('change', onMediaQueryChange)

    return () => {
      lgMediaQuery.removeEventListener('change', onMediaQueryChange)
    }
  }, [])

  return (
    <section
      id="features"
      aria-label="Features for running your books"
      className="relative overflow-hidden bg-blue-600 pb-28 pt-10 sm:py-4 "
    >
      <img
        className="absolute left-1/2 top-1/2 max-w-none translate-x-[-44%] translate-y-[-42%] rotate-180"
        src={backgroundImage}
        alt=""
        width={2245}
        height={1636}
        unoptimized
      />
      <section className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8  ">
        <motion.div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none" {...textAnimation}>
          <h2 className="font-display font-extrabold text-3xl tracking-tight text-white sm-z-[50]:text-4xl md:text-5xl lg:text-8xl">
            JOIN DIAGNOOS!
          </h2>
          {/* <p className="mt-6 text-lg tracking-tight text-blue-100" data-aos="fade-up">
            Well everything you need if you aren’t that picky about minor
            details like tax compliance.
          </p> */}
        </motion.div>

        <motion.div {...textAnimation2}>
          

        <Tab.Group
          as="div"
          className="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0"
          vertical={tabOrientation === 'vertical'}
          
          >
          {({ selectedIndex }) => (
            <>
              <div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
                <Tab.List className="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal">
                  {features.map((feature, featureIndex) => (
                    <div
                      key={feature.title}
                      className={clsx(
                        'group relative rounded-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-6 z-0',
                        selectedIndex === featureIndex
                          ? 'bg-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10'
                          : 'hover:bg-white/10 lg:hover:bg-white/5'
                      )}
                    >
                      <h3>
                        <Tab
                          className={clsx(
                            'font-display text-lg [&:not(:focus-visible)]:focus:outline-none',
                            selectedIndex === featureIndex
                            ? 'text-blue-600 lg:text-white'
                            : 'text-blue-100 hover:text-white lg:text-white'
                            )}
                            >
                          <span className="absolute inset-0 rounded-full lg:rounded-l-xl lg:rounded-r-none" />
                          {feature.title}
                        </Tab>
                      </h3>
                      <p
                        className={clsx(
                          'mt-2 hidden text-sm lg:block',
                          selectedIndex === featureIndex
                          ? 'text-white'
                          : 'text-blue-100 group-hover:text-white'
                          )}
                          >
                        {feature.description}
                      </p>
                    </div>
                  ))}
                </Tab.List>
              </div>
              <Tab.Panels className="lg:col-span-7">
                {features.map((feature) => (
                  <Tab.Panel key={feature.title} unmount={false}>
                    <div className="relative sm:px-6 lg:hidden">
                      <div className="absolute -inset-x-4 bottom-[-4.25rem] top-[-6.5rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl" />
                      <p className="relative mx-auto max-w-2xl text-base text-white sm:text-center">
                        {feature.description}
                      </p>
                    </div>
                    <div className="mt-10 w-[45rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-blue-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]">
                      <img
                        className="w-full"
                        src={feature.image}
                        alt=""
                        priority
                        sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                      />
                    </div>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </>
          )}
        </Tab.Group>

        </motion.div>
      </section>
    </section>
  )
}
