import React from "react";
import { motion } from "framer-motion";
import Hospitalmanagement from "../../Assets/heropg1.jpg";

const Medicard = () => {
  const textAnimation = {
    initial: { opacity: 0, x: 120 },
    animate: { opacity: 1, x: 0 },
    transition: { duration: 1.6 },
  };

  const textAnimation2 = {
    initial: { opacity: 0, x: -120 },
    animate: { opacity: 1, x: 0 },
    transition: { duration: 1.8 },
  };

  const people = [
    {
      name: "Doctor Near You          ",
      // role: "connect within 60 secs",
      imageUrl:
        "https://m.economictimes.com/thumb/msid-78398032,width-1200,height-900,resizemode-4,imgsize-463212/online-consultations-have-gone-up-by-200-per-cent-in-the-same-period-showing-that-there-has-been-a-clear-shift-towards-teleconsultations-.jpg",
    },

    {
      name: "Door to Door Medicine ",
      // role: "Stay informed with the latest medical updates ",
      imageUrl:
        "https://www.enukesoftware.com/blog/wp-content/uploads/2017/02/pharmacist-gives-bag-with-antibiotic-drugs-according-recipe-hands-patient-pharmacy-vector-concept_53562-7866-1200x900.jpg",
    },
    {
      name: "Labs Near you",
      // role: "Stay informed with the latest medical updates ",
      imageUrl:
        "https://wp02-media.cdn.ihealthspot.com/wp-content/uploads/sites/522/2022/06/iStock-1174677845.jpg",
    },
    {
      name: "Physiotherapist near you            ",
      // role: "Stay informed with the latest medical updates ",
      imageUrl:
        "https://www.apollospectra.com/backend/web/uploads/1293843317.jpg",
    },
    {
      name: "Physician near you",
      // role: "Stay informed with the latest medical updates ",
      imageUrl:
        "https://post.medicalnewstoday.com/wp-content/uploads/sites/3/2019/12/GettyImages-481073822-732x549.jpg",
    },
    {
      name: "Gynecologist near you",
      // role: "Stay informed with the latest medical updates ",
      imageUrl:
        "https://as1.ftcdn.net/v2/jpg/04/55/65/62/1000_F_455656223_zbyAL00KpAzbR47PVbz3wQLuCO04QN9N.jpg",
    },
    {
      name: "Dentist near you",
      // role: "Stay informed with the latest medical updates ",
      imageUrl:
        "https://dentistmissionviejooc.com/wp-content/uploads/2022/12/when-do-i-replace-dental-fillings.jpg",
    },
    {
      name: "Hospital near you",
      // role: "Stay informed with the latest medical updates ",
      imageUrl:
        "https://www.hnf-cure.org/wp-content/uploads/2012/03/surgery.jpg",
    },
    {
      name: "Dietitian/Nutrition ",
      // role: "Stay informed with the latest medical updates ",
      imageUrl:
        "https://mmi.edu.pk/wp-content/uploads/2020/04/nutritionist.jpg",
    },
    {
      name: "Immunization",
      // role: "Stay informed with the latest medical updates ",
      imageUrl:
        "https://www.optum.com/health-articles/sites/default/files/styles/article_feature/public/images/AgeByAgeGuideToKidsImmunizations-Web-1200x800-72_3.jpg?itok=cf1wsc00",
    },

    // More people...
  ];

  return (
    <>
      <div id="team">
        {/* Team section */}

        <div className=" py-8 pt-16 lg:pt-16 bg-blue-50">
          <div className="mx-auto max-w-7xl px-6 lg:px-8 ">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <motion.h2
                className="text-4xl  tracking-tight text-black sm:text-4xl lg:text-6xl font-extrabold"
                // data-aos="fade-up"
                {...textAnimation}
              >
                Services We Provide
              </motion.h2>
              <motion.p
                className="mt-6 text-lg leading-8 text-gray-600"
                // data-aos="fade-up"
                {...textAnimation}
              >
                We’re a group of individuals who are passionate about what we do
                and dedicated to delivering the best results for our clients.
              </motion.p>
            </div>

            <motion.div {...textAnimation2}>

              <div className="  mt-6 rounded-2xl shadow-xl hover:shadow-gray-500">
              <div className="group-hover:blur-sm   ">
                      <img
                        className=" w-full h-[30vh] rounded-t-2xl object-cover"
                        src={Hospitalmanagement}
                        alt="diagnoos"
                      />
                    </div>

                    <div className="px-6 py-2">
                      <h3 className=" mt-2  sm:text-md font-semibold leading-8 tracking-tight text-black">
                        
                        Hospital management System
                      </h3>
                      <p className=" text-base leading-7 text-gray-600">
                      
                       Hospitals connected - 01
                      </p>
                    </div>

              </div>

              <ul className="p-2 mx-auto mt-6 grid max-w-2xl  grid-cols-1 gap-x-5 gap-y-5 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 xl:grid-cols-5">
                {people.map((person) => (
                  <li
                    className=" group relative rounded-2xl bg-white shadow-xl hover:shadow-gray-500"
                    key={person.name}
                  >
                    <div className="group-hover:blur-sm   ">
                      <img
                        className="aspect-[14/13] w-full rounded-t-2xl object-cover"
                        src={person.imageUrl}
                        alt="diagnoos"
                      />
                    </div>
                    <div class=" absolute top-[50%] translate-y-[-20%] shadow-xl left-[40%] translate-x-[-35%] z-10 hidden group-hover:block ">
                      <div
                        // href={serviceLink}
                        className=" px-2 py-3  text-xs sm:text-base shadow-xl text-black bg-white  border-[1px] border-solid border-white rounded-md"
                      >
                        Coming Soon ...
                      </div>
                    </div>

                    <div className="px-6 py-2">
                      <h3 className=" mt-2  sm:text-md font-semibold leading-8 tracking-tight text-black">
                        {person.name}
                      </h3>
                      <p className=" text-base leading-7 text-gray-600">
                        {person.role}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Medicard;
