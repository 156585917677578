import classNames from "classnames";
import React, { useState } from "react";
import { BsChevronUp } from "react-icons/bs";
// import { motion } from "framer-motion";

import faq from "../../images/illustrations/main.png";
// import faq3 from "../../images/illustrations/doc6.png";

// import faq1 from "../../images/illustrations/aaa.gif";

import { Fade } from "react-reveal";

const FaqItem = ({ open, title, children }) => {
  const [isOpen, setIsOpen] = useState(open ? true : false);

  const iconClass = classNames({
    "transition-all duration-300": true,
    "rotate-180": isOpen,
  });

  const contentClass = classNames({
    "text-white transition-all duration-300  p-1 rounded-md overflow-hidden": true,
    "h-full": isOpen,
    "h-0": !isOpen,
  });

  return (
    <div className="mb-3 border-b border-gray-300 pb-3">
      <div
        className="flex justify-between lg:text-xl py-3 cursor-pointer hover:text-primary"
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
        <BsChevronUp className={iconClass} />
      </div>
      <div className={contentClass}>
        <p className="select-none">{children}</p>
      </div>
    </div>
  );      
};

export default function FaqSection() {  //[#0F4D52]   [#59A94F]
  return (
    // <div className="bg-gradient-to-b from-white from-10% via-white via-10% to-[#0F4D52]  to-90% pb-8 px-6">
      <div className="bg-[#0F4D52] ">
      <section className="container mx-auto pt-32 pb-8 px-2">
        <div className="grid md:grid-cols-2">
          <div className="mb-4" >
            <Fade up duration={1000}>
              <img src={faq} className="mx-auto" alt="FAQ" />
            </Fade>
          </div>
          <div className="flex justify-center text-white">
            <div className="">
              
              <h2 className="mb-6 leading-normal text-white font-display text-3xl lg:text-6xl font-extrabold" >
                Frequently asked questions...
              </h2>
              <div className="my-6 " >
                <FaqItem
                  open={true} className="text-white"
                  title="Is this website for patient or doctor or both?" 
                >
                  We want to revoltionize the whole medical community and our service is available for both patient and doctor.
                </FaqItem>
                <FaqItem open={false} title="Why Choose Diagnoos?" >
                The most important question while trusting about your health, right? The only reason why you should choose our Diagnoos is we can arrange a solution to any kind of Health related problem. Need help with anything? Just drop your query online or meet us offline and we will be right back with a great solution.
                </FaqItem>
                <FaqItem open={false} title="How Old Is Diagnoos? " >
                Diagnoos has been in business since August 2023 we know we are new in the field but we have had 100+ satisfied clients and 300+ certified Doctors
                </FaqItem>
                <FaqItem open={false} title="What Is The Cost Of The Services Provided By Us" >
                The cost will depend on the kind of work you will give us. Every field of work requires a different type of way and we charge according to it.
                </FaqItem>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
