import React from 'react'
import './Newcompmob.css';
import Innovation from "../../images/Innovation2.jpg";
import CustomerCentricity from "../../images/Customer-Centricity.jpg";
import Excellence from "../../images/Excellence.webp";
import Collaboration from "../../images/Collaboration.webp";
import Integrity from "../../images/Integrity.jpg";
import SocialResponsibility from "../../images/SocialResponsibility.jpeg";


const Newcomp = () => {

  const slideData = [
    {
      title: "Customer-Centricity",
      description: "Our customers are at the heart of everything we do. We are committed to understanding their needs and providing the highest level of service and support.( We strive to exceed their expectations at every touchpoint.)",
      image: CustomerCentricity,
    },
    {
      title: "Innovation",
      description: "We embrace a culture of innovation, constantly seeking new ideas, technologies, and approaches to improve the experiences of our clients.",
      image: Innovation,
    },
    {
      title: "Excellence",
      description: "We set high standards for ourselves and are committed to delivering quality, precision, and attention to detail in every project, product, and service we provide.We set ambitious goals and hold ourselves accountable for delivering exceptional results.",
      image: Excellence,
    },
    {
      title: "Integrity",
      description: "We uphold the highest ethical standards in our business practices. Honesty,transparency, and fairness are at the core of our interactions.",
      image: Integrity,
    },
    {
      title: "Collaboration",
      description: "We believe in the power of collaboration and teamwork. We foster an inclusive environment where diverse perspectives are valued, and everyone has a voice. By working together, we achieve greater outcomes and drive success.",
      image: Collaboration,
    },
    {
      title: "Social Responsibility",
      description: "We are committed to making a positive impact on society and the environment. We embrace sustainable practices, support social causes, and contribute to the well-being of our communities.",
      image: SocialResponsibility,
    },
   
  ];

  return (
    <div
     
    style={{
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      fontWeight: "bold",
     
    
    }}
  >
    <h1
      style={{
        display: "flex",
        justifyContent: "center",
      alignItems: "center",
        textAlign: "center",
        padding: "5vh",
        position: 'sticky',
        top: "5rem",
      }}
      className=" tracking-tight bg-white text-gray-900 sm:text-4xl font-display text-5xl lg:text-8xl font-extrabold"
    >
      Our values

    </h1>

    <p className="mt-6 text-lg  mx-auto text-center max-w-7xl leading-8 text-gray-600">
       At Diagnoos, we believe in upholding a set of core values that define who we are as an organization. These values guide our decisions, actions, and interactions with our clients and doctors. They reflect our commitment to excellence, integrity, and making a positive impact in everything we do.
       </p>

    <div className="container8">
      {slideData.map((slide, index) => (
        <div
          key={index}
          className="textdiv1 "
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div style={{ textAlign: "left", margin: "1rem" }}>
            <h1 style={{ fontSize: "2.4rem", color: "white" }}>{slide.title}</h1>
            <p>{slide.description}</p>
          </div>
          <img src={slide.image} alt={`slide_image_${index}`} />
        </div>
      ))}
    </div>
  </div>
  )
}

export default Newcomp

