import React from 'react'
import { About2 } from '../components/About/About2'
import { Price } from '../components/About/Price'

const Plans = () => {
  return (
    <>
        <About2/>
        <Price/>
    </>
  )
}

export default Plans