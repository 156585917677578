import * as React from "react";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Navbar/Mainnav.jsx";
import Footer from "./components/Footer/Footer.jsx";
import Contact from "./Pages/Contact";
import Home from "./Pages/Home";
import Plans from "./Pages/Plans.jsx";
import Abt from "./Pages/Abt.jsx";
// import Test from "./Pages/Test.jsx";
import AOS from "aos";
import "aos/dist/aos.css";

import "./App.css";
import { ThreeCircles } from "react-loader-spinner";
import Careerpg from "./Pages/Careerpg.jsx";
import Labinfo from "./components/connect_info_pg/Labinfo.jsx";
import Signin from "./Pages/Signin";
import Signup from "./Pages/Signup";

// import { motion, AnimatePresence } from "framer-motion";
import MarketMed from "./Pages/MarketMed.jsx";

// import SignUp from "./Pages/SignUp.jsx";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  const [loading, setLoading] = useState(false);
  // const [blackScreenVisible, setBlackScreenVisible] = useState(true);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setBlackScreenVisible(false);
  //   }, 2000);

  //   return () => clearTimeout(timer);
  // }, []);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  // const staggerTextAnimation = {
  //   initial: { opacity: 0, y: 20 },
  //   animate: { opacity: 1, y: 0 },
  //   exit: { opacity: 0, y: -20 },
  //   transition: { duration: 0.5, delay: 0.2 },
  // };
  // const staggerTextAnimation1 = {
  //   initial: { opacity: 0, y: 20 },
  //   animate: { opacity: 1, y: 0 },
  //   exit: { opacity: 0, y: -20 },
  //   transition: { duration: 1, delay: 0.7 },
  // };
  // const staggerTextAnimation2 = {
  //   initial: { opacity: 0, y: 20 },
  //   animate: { opacity: 1, y: 0 },
  //   exit: { opacity: 0, y: -20 },
  //   transition: { duration: 1.2, delay: 1 },
  // };
  // const gradientTextStyles = {
  //   color: "transparent",
  //   background: "-webkit-linear-gradient(45deg, #2196F3, #ffffff)",
  //   WebkitBackgroundClip: "text",
  //   fontWeight: "bold",
  //   fontSize: "4rem",

  // };
  // const customExitAnimation = {
  //   initial: { opacity: 1, y: 0 },
  //   animate: { opacity: 0, y: -100 }, // Adjust the y value based on your preference

  // };

  return (
    <>
      {loading ? (
        <div className="placeLoader">
          <ThreeCircles
            backgroundColor="black"
            color="black"
            height="100"
            width="100"
            // color="white"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor=""
            innerCircleColor=""
            middleCircleColor=""
          />
        </div>
      ) : (
        //   <AnimatePresence>
        //   {blackScreenVisible && (
        //     <motion.div
        //       key="black-screen"
        //       initial={{ backgroundColor: "#000" }}
        //       animate={{ backgroundColor: "#000" }}
        //       exit={customExitAnimation}
        //       style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, zIndex: 9999 ,  transition: "all ease 1s", display: 'flex' , justifyContent: 'center', alignItems: 'center' }}
        //     >
        //       <motion.div
        //         key="text-container"
        //         className="text-container"
        //         initial={{ opacity: 0, y: 20 }}
        //         animate={{ opacity: 1, y: 0 }}
        //         exit={{ opacity: 0, y: -20 }}
        //         transition={{ duration: 1.2, delay: 0.5 }}
        //         style={{ position: "absolute", textAlign: "center",  }}
        //       >
        //         <motion.p {...staggerTextAnimation} style={gradientTextStyles}  >
        //           WELCOME
        //         </motion.p>
        //         <motion.p {...staggerTextAnimation1} style={gradientTextStyles} >
        //           TO
        //         </motion.p>
        //         <motion.p {...staggerTextAnimation2} style={gradientTextStyles}>
        //           DIAGNOOS
        //         </motion.p>
        //       </motion.div>
        //     </motion.div>
        //   )}
        // </AnimatePresence>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Header />
                  <Home />
                  <Footer color="#94D25C" />
                </>
              }
            ></Route>

            <Route
              path="/about"
              element={
                <>
                  <Header />
                  <Abt /> <Footer color="#2563EB" />
                </>
              }
            ></Route>

            <Route
              path="/contact"
              element={
                <>
                  <Header />
                  <Contact />
                  <Footer color="#94D25C" />
                </>
              }
            ></Route>

            <Route
              path="/plans"
              element={
                <>
                  <Header />
                  <Plans />
                  <Footer />
                </>
              }
            ></Route>
            {/* <Route
              path="/tests"
              element={
                <>
                  <Header />
                  <Test />
                  <Footer />
                </>
              }
            ></Route> */}

            <Route
              path="/career"
              element={
                <>
                  <Header />
                  <Careerpg />
                  <Footer color="#2563EB" />
                </>
              }
            ></Route>

            <Route
              path="/labinfo"
              element={
                <>
                  <Header />
                  <Labinfo />
                  <Footer color="#2563EB" />
                </>
              }
            ></Route>

            <Route
              path="/Signin"
              element={
                <>
                  <Signin />
                </>
              }
            ></Route>

            <Route
              path="/Signup"
              element={
                <>
                  <Signup />
                </>
              }
            ></Route>



<Route
              path="/marketmed"
              element={
                <>
                  <Header />
                  <MarketMed/>
                  <Footer color="#2563EB" />
                </>
              }
            ></Route>

          </Routes>
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
