import React from "react";
import globoCrypto from "../../images/illustrations/main2.webp";
import backgroundImage from "../../images/bg7.svg";
import { motion } from "framer-motion";

// bg-gradient-to-b from-white from-10% via-white via-30% to-[#b7e4c7] to-90% pb-8
export default function Heropgnew() {

  const textAnimation = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.8 },
  };

  const textAnimation2 = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 1.4 },
  };

  return (
    <>
      <section className="relative overflow-hidden">
        <img
          className=" absolute left-1/2 top-1/2 max-w-none translate-x-[-44%] translate-y-[-42%]"
          src={backgroundImage}
          alt=""
          width={2245}
          height={636}
        />

        <div className=" bg-gradient-to-b from-white from-10% via-white via-30% to-[#b7e4c7] to-90% pb-8" >
          <section className="relative bg-primary pt-[140px] bg-opacity-5 pb-2">
            <div className="container px-4 mx-auto grid md:grid-cols-1" >
            {/* <div className=" lg:px-12 mx-auto grid md:grid-cols-2"> */}

              <motion.div className="flex items-center" {...textAnimation}>
                <div className="relative">
                  <h1
                    className="text-2xl text-center  text-white md:text-6xl lg:text-8xl font-bold leading-normal pl-4"
                    // data-aos="fade-up"
                  >
                    Welcome To New Era Of Healthcare
                  </h1>
                </div>
              </motion.div>
              <motion.div className="relative md:block" {...textAnimation2}>
                <img
                  src={globoCrypto}
                  className="mx-auto"
                  // data-aos="fade-up"
                  alt="Globe"
                />
              </motion.div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
}
